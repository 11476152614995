.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eff4f8;
  padding: 0.625rem 0.938rem;
  margin-top: 0.75rem;
}
.text_container {
  display: flex;
  gap: 0.625rem;
  flex: 1;
  align-items: center;
}
.text {
  font-size: 0.875rem;
  color: #262626;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 11.625rem;
  display: inline-block;
}

.sub_text {
  color: #626262;
  font-size: 0.688rem;
  font-weight: 500;
  white-space: nowrap;
  max-width: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.meta {
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: center;
}

.input {
  font-size: 0.875rem;
  width: 96%;
  flex: 1;
  background-color: #eff4f8;
  border: 2px solid gray;
  padding: 0 0.313rem;
  font-size: 13px !important;
}

.input:focus {
  border-width: 2;
}