.card {
  background-color: #ebebf7;
}

.title {
  display: inline-block;
  padding: 1rem 0;
  font-size: 1.1rem;
  font-weight: bold;
}
