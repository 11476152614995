.container {
  background-color: #ebf8ff;
  display: inline-flex;
  align-items: center;
  color: #2b6cb0;
  font-weight: 500;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.313rem 0.625rem;
  gap: 0.313rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.688rem;
}
