.box {
  min-width: 120px;
  /* width: 12rem; */
}

.label {
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  padding-bottom: 5px;
}

.progress {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}