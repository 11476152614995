.form {
  display: flex;
  flex-direction: column;
}

.actions {
  padding: 0 1.25rem;
  margin-bottom: 0.938rem;
}

.loading {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}