.wrapper {
  padding: 0 1rem;
  overflow: hidden;
}

.container {
  height: calc(100vh - 9rem) !important;
  /* height: 85vh; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: scroll;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 0px 0px;
}

.action_txt {
  color: #007aff;
  font-size: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0;
}

.history {
  color: #333 !important;
  font-weight: 500 !important;
  border: 0.5px solid #333;
}