.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.message {
  color: red;
  font-size: 12px;
}

.retry {
    margin: 0 10px;
}
