.bar {
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
}
.bar_chunk {
  height: 0.5rem;
  border-radius: 4px;
}
.heading {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.sentiment_legends {
  display: flex;
  gap: 4rem;
  margin-bottom: 0.8rem;
}
.sentiment_label {
  color: #616161;
  font-size: 0.8rem;
}
.sentiment_score {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  margin-top: 0.2rem;
}
.container {
  margin-top: 1rem;
}