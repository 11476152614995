.section_header {
    position: sticky;
    top: 68px;
    top: 0px;
    background-color: white;
    width: 100%;
    display: block;
    margin: 0px;
    padding: 10px 5px;
    z-index: 5;
}