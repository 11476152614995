.title {
    font-size: 16px;
    color: #000000;
    margin: 0;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
}
.btn {
    /* width: 130px; */
}
.action_txt {
    color: #007AFF;
    font-size: 12px;
}
.formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    padding: 15px;
  }