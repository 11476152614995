.form {
    width: 100%;
    background: #E8EBF1;
    border-radius: 6px;
    padding: 20px 24px;
}
.actions {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
}

.actions button {
    color: white;
    font-size: 14px;
    padding: 10px 12px;
    background-color: #37ad52;
}

.actions button:hover {
    color: white;
    background-color: #249e41;
}