.content_section {
  display: grid;
  grid-template-rows: auto 15.5rem 1fr;
  margin: 0 1rem;
}

.container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  column-gap: 1.25rem;
}

.content {
  flex: 6.5;
  display: flex;
  flex-direction: column;
}

.result {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: 1.25rem;
}

.history {
  flex: 2.5;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.action_btn_group {
  display: flex;
  justify-content: flex-end;
  gap: 1.25rem;
  margin-bottom: 1.875rem;
  margin-right: 1.25rem;
}

.section_summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  background-color: white;
  padding: 1.25rem;
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.section {
  border-radius: 8px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.attributes_com_section {
  display: flex;
  gap: 1.875rem;
}

.product_recommendation_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: white;
  padding: 1rem;
}
