.container {
  background-color: white;
  border-radius: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.date {
  display: inline-block;
  padding-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 300;
  color: #626262;
}

.review_text {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  max-width: 800px;
}

.author {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.3rem;
}

.author_details {
  display: flex;
  flex-direction: column;
}

.author_name {
  font-size: 0.9rem;
  color: #0c0c0c;
  font-weight: bold;
}
.website {
  font-size: 0.9rem;
  color: #8f989f;
  margin-top: -2px;
}

.quoteIcon {
  font-size: 3.3rem !important;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  color: #a19c9c;
  overflow: hidden;
}

.review_nubmer {
  background-color: #4fd1c5;
  color: white;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.content {
  flex: 1;
  line-height: 1.5;
}