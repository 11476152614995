.container {
    background: #545454;
    padding: 10px 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.responseText {
    color: white;
    margin-left: 10px;
    padding: 0;

    display: flex;
    flex-direction: column;
}

.responseText i {
    margin-top: 5px;
}

.step {
    margin-left: 10px;
}