.container {
  background: #dedaf796;
  padding: 0 !important;
  border-radius: 3px !important;
  padding: 5px 10px !important;
  border: 1px solid #d8cbf8;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.content {
  border-top: 1px solid #6247aa;
  padding: 15px;
  padding-top: 0;
}

.header {
  display: flex;
  justify-content: space-between;
}

.sub_title {
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  color: #6247aa !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  background: inherit;
  font-size: 12px;
  font-weight: 500;
  color: #6247aa;
  margin-top: 20px;
  line-height: 19px;
  white-space: pre-wrap !important;
}

.badge {
  background: #9864fb;
  font-size: 10px;
  color: white;
  font-weight: 400 !important;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 0 10px;
}

.text_area {
  font-size: 11px;
  margin-top: 15px;
  line-height: 1.8;
  width: 100%;
  background-color: #fff;
}

.edit_btn {
  padding: 0px;
  margin-right: 20px;
  color: #000 !important;
}

.ai_label_wrapper {
  padding: 15px;
}
