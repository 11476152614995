.tableContainer {
  max-height: 500px;
  overflow-y: auto;
  position: "relative";
}

.staticTableHeader {
  position: "sticky";
  z-index: 10;
  top: 0;
}

.scoreColumn {
  width: 150px;
  text-align: center;
}

.filter_btn {
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.brand_product {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.action_btn {
  padding: 0 8px !important;
}

.action_btn2 {
  display: flex;
  justify-content: center;
}

.logo {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  object-fit: contain;
}
