.warning-box {
  background-color: #fff8e1;
  color: #856404;
  padding: 6px 8px;
  border-left: 4px solid #ffc107;
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.warning-box svg {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  color: #ffc107;
}
