.form {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.textInput {
  background-color: white;
}

.reset_btn {
  color: #007aff !important;
  font-weight: 500 !important;
}
