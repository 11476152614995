.container {
    background-color: #545454;
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
    max-width: 400px;
}

.status {
    padding-left: 12px;
}