.container {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.chip_avatar {
  color: white !important;
  width: 20px !important;
  font-size: 10px !important;
  height: 20px !important;
  margin: 3px;
  background-color: #4fd1c5 !important;
}