.brand {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}

.brand_specs {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0.625rem;
}

.partition {
  border-left: 1px solid #d9d9d9;
  height: 100%;
}
