.list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.active {
  background-color: #4fd1c5 !important;
  color: #fff !important;
  padding: 0.2rem !important;
  height: 2rem !important;
  min-width: 2rem !important;
}
.inactive {
  background-color: #00000014 !important;
  color: #000 !important;
  height: 2rem !important;
  min-width: 2rem !important;
}
