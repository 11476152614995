.productName {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
}
.response_text {
  color: #616161;
}

.active {
  background-color: #4fd1c5 !important;
  color: #fff !important;
  padding: 0.2rem !important;
  height: 2rem !important;
  min-width: 2rem !important;
}
.inactive {
  background-color: #00000014 !important;
  color: #000 !important;
  height: 2rem !important;
  min-width: 2rem !important;
}

.list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.container {
    display: flex;
    flex-direction: column;
}

.chip {
  padding: 4px 10px;
  border-radius: 6px;
}
.sentiment_score {
  font-size: 14px;
  font-weight: bold;
}
.sentiment_text {
  color: gray;
  font-size: 12px;
  margin-bottom: 0.3rem;
}
.sentiment_icon {
  margin-right: 10px;
}