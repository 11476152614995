.title {
  padding: 0 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
}

.actions {
  padding: 0px 24px 14px 24px !important;
}

.content {
  margin: 0px 24px 20px 24px !important;
  padding: unset !important;
}