.title {
  font-size: 0.813rem;
  font-weight: 700;
  color: #626262;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 0.75rem;
}

.item {
  display: flex !important;
  padding: 0.15rem 0 !important;
}
.item_avatar {
  display: flex;
  justify-content: center;
  min-width: 1.875rem !important;
  margin-top: 0.375rem;
}

.avatar {
  width: 1.125rem !important;
  height: 1.125rem !important;
}

.llm {
  font-size: 0.688rem;
  color: #7a7a7a;
  font-weight: 600;
  padding-right: 0.313rem;
}
.snippet {
  font-size: 0.75rem;
  color: #626262;
  white-space: nowrap;
}

.list {
  width: 100%;
  margin: 0 auto !important;
}