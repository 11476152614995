@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.brandname img,
.brandname svg {
  margin: 0px auto;
}

.main-content {
  margin-left: 240px;
  width: 100%;
  overflow: auto;
}

.custCard {
  border: unset;
  border-radius: 8px;
  overflow: hidden;
}
.tblHeader th {
  font-size: 20px;
  font-weight: 500;
  background-color: #eff4f8;
}
.tblHeader th span {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}
.custCard {
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
}
.custCard .table > :not(:first-child) {
  border-top: unset;
}
.custCard .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #eef2f6;
  color: var(--bs-table-striped-color);
}
.custCard .table td {
  padding: 14px 10px;
}
.legent {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  display: inline-block;
  margin-right: 5px;
}
.cyanBg {
  background-color: #c7f0ec;
}
.redbg {
  background-color: #ff6f6f;
}
.btnGroup .toggleBtn {
  background: #eff4f8;
  border: unset;
  margin-right: -3px;
}
.btnGroup .toggleBtn:last-of-type {
  margin-right: 0px;
  margin-left: -3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.btnGroup .toggleBtn:focus {
  box-shadow: unset;
}
.btnGroup .toggleBtn.active {
  background-color: #848b91;
  color: #fff;
}
.graphGreyBg {
  background: #eff4f8;
  padding: 20px;
  border-radius: 10px;
}
.graphsummary {
  border-top: 1px solid #fff;
  padding-top: 15px;
  font-size: 12px;
}
.graphsummary3 {
  border-top: 1px solid #fff;
  padding-top: 15px;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.btn-purple {
  background-color: #9864fb;
  border-radius: 4px;
  padding: 5px 7px;
  border: unset;
  color: #fff;
}
.minW-140 {
  min-width: 140px;
}
.lastDaysTag-white {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 7px 5px;
  margin: 0px;
}
.lastDaysTag-transparent {
  background-color: transparent;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 7px 5px;
  margin: 0px;
}
.lastDaysTag-grey {
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 7px 5px;
  margin: 0px;
}
.font-16-bold {
  font-size: 16px;
  font-weight: 600;
}
.darkgrey {
  color: #7a7a7a;
  font-weight: 600;
}
.font-10 {
  font-size: 10px;
}
.darker-grey {
  color: #626262;
}
.summaryList {
  padding-left: 20px;
  margin-bottom: 0px;
}
.lineHeight2Em {
  line-height: 2em;
}
.font14-600 {
  font-size: 14px;
  font-weight: 600;
}
.font-11 {
  font-size: 11px;
}
.font-16 {
  font-size: 16px !important;
}
.mwqGroup {
  background-color: #eff4f8;
  padding: 3px;
  border-radius: 4px;
  height: 38px;
  display: flex;
  align-items: center;
}
.mwqGroup button {
  color: #7a7a7a;
  border: unset;
  background: transparent;
  padding: 4px 6px;
  border-radius: 4px;
  height: 34px;
}
.mwqGroup button.active {
  background-color: #848b91;
  color: #fff;
}
.custGrid {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.custGrid .gridHeading,
.custGrid .gridContent {
  width: 100%;
  display: flex;
  align-items: center;
}
.custGrid .gridHeading div.headingTitle {
  width: 33%;
  color: #808080;
  text-align: center;
}
.custGrid .gridHeading div.headingTitle:first-of-type,
.custGrid .gridContent div.content:first-of-type {
  width: 34%;
}
.custGrid .gridContent {
  border: 1px solid #eef2f6;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.custGrid .gridContent div.content {
  width: 33%;
  font-size: 18px;
  color: #1b2559;
}
.custGrid .gridContent div.content span small {
  font-size: 14px;
}
.bg-lightBlue {
  background-color: #eff4f8;
  border-radius: 8px;
}
.brandFilter {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  max-width: 80%;
  margin: 0px auto;
  min-height: 40px;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
}
.mt-minus-20 {
  margin-top: -20px;
}
.brand-dd {
  width: 120px;
  position: relative;
  padding-right: 5px;
}
.brand-dd:after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  background: #262626;
}
.brand-dd select,
.brand-dd select:focus {
  border: unset;
  width: 100%;
  outline: unset;
}
.brandSearch input,
.brand-dd input:focus {
  border: unset;
  width: 100%;
  outline: unset;
  box-shadow: unset;
  padding: 0px 10px;
}
.brandFiltertabs .nav-tabs {
  border-bottom: 1px solid #ffffff;
  justify-content: center;
}
.brandFiltertabs .nav-tabs .nav-link {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  border: unset;
  border-bottom: 1px solid #ffffff;
  box-shadow: unset;
  outline: unset;
}
.brandFiltertabs .nav-tabs .nav-item.show .nav-link,
.brandFiltertabs .nav-tabs .nav-link.active,
.nav-tabs .brandFiltertabs .nav-link:focus,
.brandFiltertabs .nav-tabs .nav-link:hover {
  color: #262626;
  background-color: transparent;
  border: unset;
  border-bottom: 1px solid #007aff;
  box-shadow: unset;
  outline: unset;
}
.brandFiltertabs .nav-tabs .nav-item.show .nav-link,
.brandFiltertabs .nav-tabs .nav-link.active {
  font-weight: 600;
}
.brandBtnGroup button.btn-primary {
  background-color: rgba(0, 122, 255, 1);
  width: 110px;
}

.brandBtnGroup .btn-transparent {
  background-color: transparent;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.85);
  box-shadow: unset !important;
}
.greyAcc .accordion .accordion-item {
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  border: unset;
  overflow: visible;
  background-color: rgba(0, 0, 0, 0.02);
}
.greyAcc .accordion .accordion-item .accordion-button:not(.collapsed),
.greyAcc .accordion .accordion-item .accordion-button {
  color: #3a3a3a;
  background-color: transparent;
  box-shadow: unset;
  max-width: 150px;
  padding: 10px;
}
.greyAcc .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.iconWrap i.feather {
  color: #3a3a3a;
}
.greyAcc .accordion .accordion-item .accTitleCyan span {
  background-color: #4fd1c5;
  padding: 10px;
  margin-left: -20px;
  color: #fff;
}
.accContentTitle {
  font-size: 16px;
  font-weight: 500;
}
.accList {
  margin-top: 0px;
}
.greyTxt {
  color: #4f4f4f;
}
.lightBlueBg {
  background-color: #eff4f8;
  padding: 10px;
}
.borderRadius8 {
  border-radius: 8px;
  overflow: hidden;
}
.border-none {
  border: unset;
}
#righsmallAcc button {
  box-shadow: unset;
  background-color: transparent;
}
.accordion-button:not(.collapsed) {
  border-bottom: 0px solid transparent;
}
#righsmallAcc .accordion-item:first-of-type,
#righsmallAcc .accordion-item:last-of-type {
  border-radius: 8px;
  overflow: hidden;
}
#righsmallAcc .accordion-button {
  border-bottom: 1px solid #ccc;
  color: #262626;
}
#righsmallAcc .accordion-button.collapsed {
  border-bottom: 0px solid transparent;
  color: #262626;
}
#righsmallAcc .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accList a {
  color: #479fff;
}
.dmqGroup {
  background-color: #fff;
  padding: 3px;
  border-radius: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  border: 1px solid #e2e8f0;
}
.dmqGroup button {
  color: #7a7a7a;
  border: unset;
  background: transparent;
  padding: 4px 6px;
  border-radius: 4px;
  height: 38px;
}
.dmqGroup button.active {
  background-color: #eff4f8;
  color: #000000;
}

/************ LOGIN CSS ***********/
.login-right {
  background-image: url("../images/login-bg.svg");
  background-size: cover;
}
.login-right .langImg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  max-width: 380px;
  max-height: 380px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.21);
  border: 1px solid rgba(255, 255, 255, 0.52);
}
.loginfield .input-group {
  background-color: rgba(79, 209, 197, 0.2);
  border-radius: 16px;
}
.loginfield .input-group .input-group-text {
  background-color: transparent;
  padding: 8px;
  border: unset;
}
.loginfield .input-group input,
.loginfield .input-group input:focus,
.loginfield .input-group input:focus-visible {
  background: transparent;
  border: unset;
  outline: unset;
  box-shadow: unset;
  width: calc(100% - 40px);
  padding-left: 0px;
  font-size: 14px;
}
.btn-cyan,
.btn-cyan:hover,
.btn-cyan:focus {
  background: linear-gradient(99.78deg, #4fd1c5 -5.85%, #22caba 109.55%);
  background: -webkit-linear-gradient(
    99.78deg,
    #4fd1c5 -5.85%,
    #22caba 109.55%
  );
  background: -moz-linear-gradient(99.78deg, #4fd1c5 -5.85%, #22caba 109.55%);
  color: #fff;
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.16);
  width: 130px;
  height: 52px;
  outline: unset;
  border-radius: 16px;
}
.loginOthers {
  width: 100%;
  height: 1px;
  position: relative;
  background: rgba(240, 237, 255, 1);
}
.otherOtp {
  position: absolute;
  background-color: #fff;
  padding: 1px 10px;
  top: -10px;
  left: 0px;
  right: 0px;
  display: inline-block;
  margin: 0px auto;
  width: 132px;
}
.otherloginBtns {
  text-align: center;
  border: 1px solid #f0edff;
  border-radius: 16px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.scrolly {
  overflow-y: auto;
  height: 100%;
}

/*******Brand Product Dashboard css*******/

.reachCount {
  border-bottom: 1px solid #ffffff;
  margin-left: -8px;
  text-align: center;
  margin-top: -20px;
  padding: 20px;
}
.reachCount:last-of-type {
  margin-right: -8px;
  margin-left: 0px;
  border-left: 1px solid #fff;
}
.reachCount .prdCount {
  font-weight: 100;
  font-size: 18px;
  color: #626262;
}
.prdSummary {
  margin-left: -20px;
  margin-right: -20px;
}
.prdSummary div.idReSe {
  padding: 0px 20px;
}
.prdtblHeader th {
  font-size: 16px;
  font-weight: 500;
  background-color: #d6e3ed;
  padding: 12px 15px;
}
.prdtblHeader th:first-of-type {
  border-top-left-radius: 10px;
}
.prdtblHeader th:last-of-type {
  border-top-right-radius: 10px;
}

i.feather {
  font-size: 24px;
}
.feather {
  font-family: "feather" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}
.brandname img,
.brandname svg {
  margin: 0px auto;
}
.brandBtnGroup button.btn-primary {
  background-color: rgba(0, 122, 255, 1);
  width: 110px;
  color: #fff;
}

.brandBtnGroup {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.brandBtnGroup .btn-transparent {
  background-color: transparent;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.85);
  box-shadow: unset !important;
}
.sidepanel {
  width: 240px;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  padding: 0px;
  background: #eff4f8 !important;
}

header {
  height: 70px;
  background: #fff;
  width: 100%;
  padding: 15px 20px;
}

.bodycontent {
  background: #f8f9fa;
  height: calc(100vh - 70px);
  padding: 20px;
  overflow: hidden;
  overflow-y: auto;
}

.sidepanel .nav-pills .nav-link.active,
.sidepanel .nav-pills .show > .nav-link {
  background-color: #fff;
  color: #2d3748;
}
.sidepanel .feather {
  font-size: 14px;
}
.sidepanel .nav .nav-item {
  margin-bottom: 10px;
}
.sidepanel .nav .nav-item:last-of-type {
  margin-bottom: 0px;
}
.sidepanel .nav .nav-item .nav-link {
  cursor: pointer;
  padding: 16px;
}
.sidepanel .nav-pills .nav-link.active span.iconWrap {
  background: #4fd1c5;
  color: #fff;
}
.sidepanel span.iconWrap {
  background: #fff;
  border-radius: 4px;
  padding: 7px 8px;
}

.customTabs {
  background-color: #eff4f8;
  border-radius: 8px 8px 0px 0px;
}
.customTabs .nav-tabs,
.customTabs .nav-tabs .nav-link {
  border: unset;
}
.customTabs .nav-tabs .nav-link {
  color: #000;
  min-width: 120px;
}
.customTabs .nav-tabs .nav-item.show .nav-link,
.customTabs .nav-tabs .nav-link.active {
  border: 0px solid transparent !important;
  padding: 17px 20px;
}
.customTabs .nav-tabs .nav-link:focus,
.customTabs .nav-tabs .nav-link:hover {
  border: 0px solid transparent !important;
  background-color: #fff;
}

.customTabContent.tab-content {
  padding: 20px;
  background: #fff;
  border-radius: 0px 0px 8px 8px;
}
.customTabContent .input-group-text {
  background-color: transparent;
  border-right-color: transparent;
  border-radius: 6px;
}
.customTabContent .form-control {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-left-color: transparent;
  font-size: 16px;
  padding-left: 0px;
}
.customTabContent .form-control:focus,
.customTabContent .form-control:hover {
  box-shadow: unset;
  border: 1px solid #ced4da;
  border-left-color: transparent;
}
.custCard {
  border: unset;
  border-radius: 8px;
  overflow: hidden;
}
.tblHeader th {
  font-size: 20px;
  font-weight: 500;
  background-color: #eff4f8;
}
.tblHeader th span {
  color: #808080;
  font-size: 14px;
  font-weight: 400;
}
.custCard {
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
}
.custCard .table > :not(:first-child) {
  border-top: unset;
}
.custCard .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #eef2f6;
  color: var(--bs-table-striped-color);
}

.custCard .table td {
  padding: 14px 10px;
  border: none !important;
}

.custCard .table tr {
  border: none !important;
}

.custCard .table th {
  border: none !important;
}

.custCard .table thead {
  border: none !important;
}

.legent {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  display: inline-block;
  margin-right: 5px;
}
.cyanBg {
  background-color: #c7f0ec;
}
.redbg {
  background-color: #ff6f6f;
}
.btnGroup .toggleBtn {
  background: #eff4f8;
  border: unset;
  margin-right: -3px;
}
.btnGroup .toggleBtn:last-of-type {
  margin-right: 0px;
  margin-left: -3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.btnGroup .toggleBtn:focus {
  box-shadow: unset;
}
.btnGroup .toggleBtn.active {
  background-color: #848b91;
  color: #fff;
}

.graphsummary2 {
  border-top: 1px solid #fff;
  padding-top: 15px;
  font-size: 12px;
  margin-top: -100px;
}
.btn-purple {
  background-color: #9864fb;
  border-radius: 4px;
  padding: 5px 7px;
  border: unset;
  color: #fff;
}
.btn-purple2 {
  background-color: #9864fb;
  border-radius: 4px;
  padding: 5px 7px;
  border: unset;
  color: #fff;
  height: 40px;
  width: 49%;
  font-size: 16px;
}
.minW-140 {
  min-width: 140px;
}
.lastDaysTag-white {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 7px 5px;
  margin: 0px;
}
.lastDaysTag-grey {
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 7px 5px;
  margin: 0px;
}
.font-16-bold {
  font-size: 16px;
  font-weight: 600;
}

.font-16 {
  font-size: 16px;
}
.darkgrey {
  color: #7a7a7a;
  font-weight: 600;
}
.font-10 {
  font-size: 10px;
}
.darker-grey {
  color: #626262;
}
.summaryList {
  padding-left: 20px;
  margin-bottom: 0px;
}
.summaryList2 {
  margin-bottom: 0px;
}
.lineHeight2Em {
  line-height: 2em;
}
.font14-600 {
  font-size: 14px;
  font-weight: 600;
}
.font-11 {
  font-size: 11px;
}
.mwqGroup {
  background-color: #eff4f8;
  padding: 3px;
  border-radius: 4px;
  height: 38px;
  display: flex;
  align-items: center;
}
.mwqGroup button {
  color: #7a7a7a;
  border: unset;
  background: transparent;
  padding: 4px 6px;
  border-radius: 4px;
  height: 34px;
}
.mwqGroup button.active {
  background-color: #848b91;
  color: #fff;
}
.custGrid {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.custGrid .gridHeading,
.custGrid .gridContent {
  width: 100%;
  display: flex;
  align-items: center;
}
.custGrid .gridHeading div.headingTitle {
  width: 33%;
  color: #808080;
  text-align: center;
}
.custGrid .gridHeading div.headingTitle:first-of-type,
.custGrid .gridContent div.content:first-of-type {
  width: 34%;
}
.custGrid .gridContent {
  border: 1px solid #eef2f6;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.custGrid .gridContent div.content {
  width: 33%;
  font-size: 18px;
  color: #1b2559;
}
.custGrid .gridContent div.content span small {
  font-size: 14px;
}

.dmqGroup {
  background-color: #fff;
  padding: 3px;
  border-radius: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  border: 1px solid #e2e8f0;
  width: 100%;
}
.dmqGroup button {
  color: #7a7a7a;
  border: unset;
  background: transparent;
  /* padding: 4px 8px; */
  border-radius: 4px;
  height: 38px;
}
.dmqGroup button.active {
  background-color: #eff4f8;
  color: #3a3a3a;
}

.brand {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
  width: 84px;
  height: 19px;
  margin-top: -10px;
  margin-left: 10px;
  line-height: 16px;
}

.no-borders {
  border-collapse: collapse;
}

.no-borders th,
.no-borders td {
  border: none !important;
}

.loader-icon {
  font-size: 48px; /* Adjust the size as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
}

/* Keyframes for rotation */
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  /* 100% {
    transform: translate(-50%, -50%) rotate(360deg);
  } */
}

.custom-tooltip-container {
  background-color: #1f1a5f;
  padding: 20px;
  border-radius: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust as needed */
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
