@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

*:not(.fa-solid):not(.fa-regular):not(.fa-light):not(.fa-thin):not(.feather) {
  font-family: "Inter", "Ubuntu", "Roboto Flex", sans-serif !important;
}

/* body {
  margin: 0;
  font-family: "Outfit" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  font-weight: 400;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen {
  .print-only {
    display: none !important;
  }

  .no-print {
    display: block;
  }
}
@media print {
  .print-only {
    display: block;
  }
  .no-print {
    display: none !important;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}