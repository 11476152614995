.title {
    font-size: 16px;
    color: #000000;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
}
.btn {
    /* width: 10px; */
}
/* .formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    padding: 15px;
  } */
  .select{
    margin-top: 15px;
    width: 100%;
  }

  .divContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }
  .formGrid  {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    /* width: 100%; */
     margin-top: 10px;
    /* padding: 15px; */
  }

  .buttonDiv{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }