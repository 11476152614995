.path {
  color: #545454;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.heading {
  font-size: 1.1rem;
  font-weight: 700;
}

.label {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}

.copy_container {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 0.4rem;
  border-radius: 4px;
}
