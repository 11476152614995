.rankIcon {
  background-color: rgb(153, 152, 152);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: 14px;
}

.active_icon {
  background-color: #24c3b5;
}
.active_text span {
  color: #009c8e;
  font-weight: bold !important;
}
