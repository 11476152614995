.container {
  /* margin: 1rem; */
  width: 100%;
}

.wrapper {
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.card {
  padding: 0;
}
