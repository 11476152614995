.tooltip_wrapper {
  display: contents;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 18.75rem;
  padding: 0.4rem;
}

.content h6 {
  border-bottom: 1px solid #dedede;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
}

.action_btn_wrapper {
  margin-top: 0.625rem;
  display: flex !important;
  align-self: flex-end !important;
}

.action_btn {
  text-transform: capitalize;
  font-weight: 300 !important;
  color: white !important;
  border: 1px solid white !important;
}

.action_btn:hover {
  border: 1px solid white !important;
}
