.title {
  display: inline-block;
  padding: 1rem 0;
  font-size: 1.1rem;
  font-weight: bold;
}
.table_container {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
