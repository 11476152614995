.container {
    max-height: 700px;
    overflow-y: scroll;
}
.header {
    position: sticky;
    top: 0;
    z-index: 10 !important;
    background-color: rgb(244, 244, 244);
    height: 70px !important;
}
.section {
    padding: 0px;
    margin-top: 0px;
}