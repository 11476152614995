.container {
  display: flex;
  flex-direction: row;
}

.form_container {
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 27%;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.login_txt {
  color: #525252;
  font-size: 16px;
}

.login_btn {
  width: 200px;
  margin-top: 20px;
  align-self: center;
  background: linear-gradient(99.78deg, #4fd1c5 -5.85%, #22caba 109.55%);
  font-weight: 800 !important;
  box-shadow: 0px 8px 21px 0px #00000029;
}

.forgot_pass {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  cursor: pointer;
  color: gray;
  font-size: 12px;
}

.input {
  background: #4fd1c533;
  margin: 10px 0;
}

.background_section {
  flex: 1;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto_fix_wrapper {
  border: 1px solid white;
  padding: 50px;
  border-radius: 46px;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
}

.auto_fix_icon {
  font-size: 40px !important;
  color: #ff7a00;
}

.auto_fix {
  width: 79px;
  height: 79px;
  background: white;
  position: absolute;
  bottom: -30px;
  border-radius: 50%;
  left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
