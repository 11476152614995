.actions button {
    padding: 5px 12px;
    color: white;
    font-size: 14px;
}

.container {
    background-color: #E8EBF1;
    padding: 30px 40px;
    border-radius: 5px;
}
.results {
  background-color: #000000cf;
  color: white;
}
.cardHeaders {
  /* background-color: #f0e0e0cf; */
  color: white;
  border: 0.7px solid #ccc;
}
.md {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 8px 15px;
}
.badge_wrapper {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}

.header_badge_wrapper {
  padding-top: 10px;
  margin-bottom: 15px;
  position: relative;
}
.badge {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -30px;
}

.headerbadge {
  background: #4aa35d;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  /* left: -10px; */
  top: -6.5px;
}
