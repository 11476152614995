.results {
  padding: 30px;
}

:global(table) {
  /* margin-bottom: 24px !important; */
}

:global(a.disabled) {
  pointer-events: none;
  cursor: default;
}

:global(.pending_link) {
  color: #b8b856;
}