.brand_product {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.brand_product img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.status {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.action_btn {
    padding: 0 8px !important;
}
.competitor {
    padding: 3px;
}
