.header {
  padding-top: 1.563rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  min-width: unset;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eff4f8;
  border-radius: 10px;
  position: relative;
  min-width: 25%;
}

.container .MuiCard-root {
  min-width: unset;
}

.card {
  background: #eff4f8;
  padding: 0 !important;
}

.score {
  font-weight: 700;
  font-size: 2.813rem;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;
  padding-bottom: 1.875rem;
  padding-top: 1.25rem;
}

.logo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 6px;
}

.title {
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10.313rem;
}

.sub_content {
  display: flex;
  flex-direction: column;
}

.divider {
  margin: 0.313rem 0;
}

.sub_score {
  font-size: 1.125rem;
  font-weight: 600;
}

.add_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.settings {
  position: absolute !important;
  top: 0;
  right: 0;
}

.core-up-icon {
  margin-top: 20px;
  font-size: 20px;
}

.core-down-icon {
  margin-bottom: 0px;
  font-size: 20px;
}

.coreDelta {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.2;
  margin-left: -3px;
  margin-top: -50px;
}

.core-up-icon-2 {
  margin-top: 5.5px;
  font-size: 13px;
}

.core-down-icon-2 {
  margin-bottom: 5px;
  font-size: 13px;
}

.coreDelta2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  margin-left: -5px;
  /* margin-top: -40px; */
}
