.input_group {
  display: flex;
  flex-direction: column;
  /* gap: 0.938rem;
  justify-content: space-between;
  align-items: flex-start; */
  margin-bottom: 15px !important;
}

.type_radio {
  /* margin-bottom: 1.875rem !important; */
}

.input_group > * {
  flex-grow: 1;
}

.form {
  padding: 0 1.25rem;
  background-color: white;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-top: 0.5rem;
}

.form button {
  margin-top: 20px;
}

.error {
  font-size: 10px;
  color: red;
}

.loading {
  position: absolute;
  top: 2rem;
  right: 0.625rem;
}

.brand_input {
  position: relative;
  flex: 1;
}

.hint_text {
  font-size: 10px;
  padding-top: 5px;
  display: inline-block;
}

.hint_chip {
  font-size: 10px !important;
  cursor: pointer;
  color: #1976d2;
  padding: 0 4px;
  font-weight: bold;
}

.start_new {
  text-align: center;
  margin-top: 0px;
  font-size: 0.75rem;
  padding-top: 4px;
  color: #1976d2 !important;
  cursor: pointer;
}

.input_section {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding-top: 0.625rem !important;
}
.radio {
  margin-right: 20px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.variant_text {
  color: #007166;
  padding: 0.2rem 0.6rem;
  font-size: 0.72rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 0.4rem;
  display: inline-block;
  background-color: #dff9f6;
}
