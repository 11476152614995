.container {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.563rem;
  padding: 1.875rem 1.563rem;
}

.title {
  padding: 0.938rem;
  border-bottom: 1px solid #e4e4e4;
}

.card {
  padding: 0 !important;
}

.error {
  grid-column: span 4;
  padding: 2rem 0;
}
