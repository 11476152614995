.brand-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  
  .brand-card {
    /* border: 1px solid #ccc; */
    padding: 10px;
    width: 97%; /* Adjust the width as needed */
    margin-left: 1.5%;
  }
  
  .brand-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .brand-table th,
  .brand-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .brand-table th {
    background-color: #f2f2f2;
  }

  .header-text {
    text-align: center;
  }

  .mainDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  