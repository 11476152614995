.container {
  background-color: #000;
    border-radius: 5px;
    overflow: scroll;
}
.results {
  background-color: #000000cf;
  color: white;
}
.md {
  border-radius: 10px;
  padding: 12px 25px;
}
.badge_wrapper {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}
.badge {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -30px;
}
.actions {
  display: flex;
  padding-bottom: 2px;
  justify-content: flex-end;
}
.btn {
  color: white !important;
}
