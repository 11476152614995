.wrapper {
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.25rem;
  gap: 1.25rem;
  position: relative;
}

.title_container {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  flex-direction: row;
  margin-bottom: 8px;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
}

.brand_container {
  flex: 1;
  padding: 0 0.938rem;
}

.main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  position: relative;
}

.brand_index_wrapper {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: minmax(38%, auto) 62%;
  grid-gap: 1.25rem;
}

.brand_index {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.sub {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
}

.brand_index h1 {
  font-size: 5.625rem;
  font-weight: 600;
}

.divider {
  width: 80%;
  height: 1px !important;
  background-color: #e4e4e4;
}

.score {
  display: flex;
  margin: 0.938rem;
}

.score_content {
  display: flex;
  flex-direction: column;
  min-width: 5.625rem;
}

.score_text {
  font-size: 1rem;
  position: relative;
  top: 0.625rem;
}

.score_value {
  font-size: 3rem;
  font-weight: 600;
  color: #242424;
  width: 40px;
  cursor: pointer;
}

.start_monitoring {
  color: #007aff !important;
  font-weight: 500 !important;
}

.start_monitoring:focus {
  border: inherit;
}

.header {
  background-color: white;
  height: auto !important;
  padding: 0px !important;
}

.title {
  font-size: 1.25rem;
}

.card {
  padding: 0;
}

.partition {
  border-top: 1px solid #d9d9d9;
  width: 100%;
}

.info {
  position: absolute !important;
  top: -0.5rem;
  right: -0.25rem;
}

.label_container {
  display: flex;
  align-items: center;
  gap: 0.313rem;
  margin-top: 3px;
}

.brand_label {
  font-size: 1rem;
}

.title_label {
  font-size: 0.688rem;
  color: #626262;
}

.error_view {
  padding: 7rem 0;
}

@media print {
  .print_only {
    position: absolute;
    top: 0.625rem;
    right: 1.25rem;
  }
}

.core-up-icon {
  margin-top: 4px;
  font-size: 22px;
}

.core-down-icon {
  margin-bottom: 12.5px;
  font-size: 22px;
}

.coreDelta {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2;
  margin-left: -3px;
  margin-top: -50px;
}