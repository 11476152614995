.brand_image {
  width: 400px;
  height: 420px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.item {
  flex: 0 0 calc(100% / 3);
  scroll-snap-align: start;
  box-sizing: border-box;
  border: 1px solid #ddd;
  position: relative;
  margin-left: 5px;
}

.content {
  padding: 1rem;
}

.name {
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.scores {
  display: flex;
  justify-content: space-around;
  background-color: #f2f6fa;
  padding: 1rem;
  border-radius: 4px;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score {
  font-size: 17px;
  font-weight: bold;
}

.label {
  margin-top: 0.1rem;
  font-size: 14px;
}

.procons {
  margin: 1rem 0;
  height: 11.85rem;
}

.list {
  list-style: none;
  margin: 0.5rem 0 !important;
}

.list li {
  padding: 0.2rem 0;
}

.list_heading {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.list_heading_text {
  font-size: 15px;
  font-weight: 500;
}