.error {
  padding: 16px 0;
}
.card_content {
  padding: 0px 16px !important;
}
.list {
  width: 100%;
  bgcolor: "background.paper";
}
