.App {
  background-color: #f6f9fa;
  min-height: 100vh;
  /* padding-bottom: 4rem; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* MainPage */
.height {
  padding: 20px 20px !important;
  border-radius: 5px !important;
  font-size: 23px !important;
  color: #9da3a9 !important;
}

.height0 {
  padding: 8px 14px !important;
  border-radius: 2px !important;
  font-size: 20px !important;
  color: #9da3a9 !important;
  box-shadow: none !important;
  border: 1px solid #9da3a982 !important;
  font-weight: 100 !important;
}

.height1 {
  padding: 8px 14px !important;
  border-radius: 2px !important;
  font-size: 19px !important;
  color: black !important;
  cursor: pointer;
  box-shadow: none !important;
  border: none !important;
  font-weight: 370 !important;
}

.height3 {
  padding: 8px 14px !important;
  border-radius: 2px !important;
  font-size: 19px !important;
  color: #9da3a9 !important;
  cursor: pointer;
  box-shadow: none !important;
  border: none !important;
  font-weight: 350 !important;
}

.height2 {
  padding: 8px 14px !important;
  border-radius: 2px !important;
  font-size: 20px !important;
  cursor: pointer;
  font-weight: 100 !important;
}

.custom-placeholder::placeholder {
  font-size: 21px !important;
  color: #9da3a9 !important;
  font-family: system-ui !important;
}

.custom-checkbox {
  border: 1px solid #ced4da !important;
  background-color: #ffffff !important;
}

.dropdownllms {
  padding: 1px 12px !important;
  border-radius: 2px !important;
  font-size: 20px !important;
  color: #000000c7 !important;
  cursor: pointer;
  border: 1px solid #ced4da !important;
  background-color: #ffffff !important;
  font-weight: 220 !important;
}

input.form-check-input {
  background-image: url("/public/check-mark.png");
}

/* .form-check-input:checked {
  background-color: #37ad52 !important;
  border-color: #1b7630 !important;
  background-image: none !important;
} */

ul.nav.brand-tabs {
  position: relative;
  margin: 15px 0 20px 14px;
}

ul.nav.brand-tabs li {
  margin-right: 20px;
}

ul.nav.brand-tabs .active {
  padding: 10px 0;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

ul.nav.brand-tabs .active span {
  display: inline-block;
  background: #37ad52;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px #1b7630;
  position: relative;
  top: 2px;
}

ul.nav.brand-tabs a span {
  display: inline-block;
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px #1b7630;
  position: relative;
  top: 2px;
}

ul.nav.brand-tabs a {
  padding: 10px 0;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 9.855em !important;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
@media screen and (max-width: 420px) {
  .dropdown-toggle::after {
    margin-left: 90px !important;
  }
  /* 
  body {
    background-color: red !important;
  } */
}
@media screen and (max-width: 767px) {
  textarea.big1.form-control {
    width: 100% !important;
  }
}
button#dropdown-basic {
  width: 100%;
}
span.dropdown-text.lucnhbtn {
  float: left;
}
.dropdown-toggle::after {
  float: right;
  margin-top: 12px !important;
}
.border .p-3 {
  padding: 1rem 4px !important;
}
.dropdownllms.dropdown {
  margin-bottom: 1rem;
}
.form-check-input[type="radio"] {
  border-color: #898a8c;
}
.btn.show {
  border: none !important;
}

.btn {
  --bs-btn-padding-x: 0rem !important;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.2rem !important;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: none !important;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-check .form-check-input {
  float: left !important;
  margin-left: 0 !important;
  /* border-radius: 15px !important; */
}

.custom-checkbox .form-check-input:checked {
  background-color: #3dc863 !important;
  border-color: #3dc863 !important;
  border-radius: 15px !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.mb {
  margin-bottom: 5rem;
}

label {
  display: inline-block !important;
  /* margin-left: 10px !important; */
}

.btn-primary {
  --bs-btn-border-color: none !important;
  border-radius: 5px !important;
}

.bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.dboxcont {
  background: #000000cf;
  padding: 20px 20px;
  position: relative;
  color: #fff;
}

.whi {
  background-color: white !important;
}

.dboxcont .card-header-actions {
  position: absolute;
  right: 18px;
  color: #ffffff94;
  justify-content: flex-end;
}

ul.focus-on {
  list-style: none;
  padding: 0 20px;
}
ul.focus-on li {
  margin-bottom: 15px;
  line-height: 25px;
}

.card-header-actions {
  padding-top: 0.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

.card-header-action {
  display: block;
  margin-left: 0.25rem;
}

.fa-solid,
.fas {
  font-weight: 900;
}

.fa,
.fa-brands,
.fa-classic,
.fa-regular,
.fa-sharp,
.fa-solid,
.fab,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fa-classic,
.fa-regular,
.fa-solid,
.far,
.fas {
  font-family: "Font Awesome 6 Free";
}

span.brnd {
  background: #37ad52;
  color: #fff;
  padding: 5px;
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 10px;
  position: relative;
  left: -38px;
}

h4.card-title {
  margin-bottom: 20px !important;
  font-weight: 400;
}

.dboxcont h4 {
  color: #fff;
}

.dboxcont p {
  color: #fff;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.dboxcont ul li {
  color: #fff;
}

.small,
small {
  font-size: 77%;
  font-weight: 400;
}

.histoblck {
  display: flex;
  justify-content: space-between;
  background: #545454;
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  margin-bottom: 10px;
}

h4.card-title2 {
  font-size: 16px;
  font-weight: 400;
}

svg:not(:root) {
  overflow: hidden;
}

img,
svg:not(:root) {
  vertical-align: middle;
}

.text {
  font-size: 18px;
  font-weight: 500 !important;
}

.borderSet {
  position: relative;
}

.text1 {
  position: absolute;
  top: -17px;
  background: #f6f9fa;
  padding: 0px 10px;
  left: 40px;
}

.back {
  background-color: #eceff0;
  margin-top: -30px;
}

.mt {
  margin-top: 6rem;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

/* chat */

.iconInner {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background: #37ad52;
  background-position: 50%;
  background-size: 300%;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 1.7em;
  height: 2em;
  justify-content: center;
  width: 2em;
}

.fa,
.fa-brands,
.fa-classic,
.fa-regular,
.fa-sharp,
.fa-solid,
.fab,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.botIcon {
  bottom: 15px;
  right: 15px;
  position: fixed;
  z-index: 9999;
}

/* .botIcon.showBotSubject {
  right: 310px;
} */

.botIcon .Layout-expand {
  display: none;
  height: 400px;
  max-height: 100vh;
  min-height: 300px;
}

.botIcon .Layout {
  -webkit-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  animation: appear 0.15s cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: rgb(63, 81, 181);
  bottom: 20px;
  border-radius: 10px;
  box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
  box-sizing: content-box !important;
  color: rgb(255, 255, 255);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-height: 27rem;
  max-width: 300px;
  min-width: 50px;
  opacity: 0;
  pointer-events: auto;
  position: fixed;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1),
    border-radius 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    background-color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s,
    color 50ms cubic-bezier(0.25, 0.25, 0.5, 1) 0.15s;
  z-index: 999999999;
}

.botIcon .Layout-open {
  border-radius: 10px;
  color: #fff;
  height: 440px;
  max-height: 500px;
  max-width: 300px;
  overflow: hidden;
  -webkit-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  -ms-transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  transition: right 0.1s cubic-bezier(0.25, 0.25, 0.5, 1),
    bottom 0.1s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-width 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    max-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    min-height 0.2s cubic-bezier(0.25, 0.25, 0.5, 1.1),
    border-radius 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    background-color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1),
    color 0ms cubic-bezier(0.25, 0.25, 0.5, 1.1);
  width: 100%;
  right: 15px;
  opacity: 1;
}

.botIcon .Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.botIcon .Messenger_messenger {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.botIcon .Messenger_header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #37ad52;
  color: rgb(255, 255, 255);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 40px;
  padding-left: 10px;
  padding-right: 40px;
}

.botIcon .Messenger_header,
.botIcon .Messenger_messenger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.botIcon .Messenger_header h4 {
  -webkit-animation: slidein 0.15s 0.3s;
  -ms-animation: slidein 0.15s 0.3s;
  animation: slidein 0.15s 0.3s;
  -webkit-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-size: 16px;
  /* opacity: 0; */
}

.botIcon .Messenger_prompt {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat_close_icon {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 12px;
  z-index: 9;
}

.botIcon .Messenger_content {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 80px;
}

.defltBox {
  color: #444;
  padding: 10px 10px;
  font-size: 13px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.defltBox ul {
  margin: 0;
  padding: 0;
}

.defltBox ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ccc;
  margin-bottom: 1px;
  padding: 0 10px;
}

.defltBox ul li svg {
  width: 17px;
  fill: #7c7c7c;
}

.botIcon .Messages {
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.botIcon .Messages,
.botIcon .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.botIcon .Messages_list {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.botIcon .Input {
  background-color: #fff;
  border-top: 1px solid #e6ebea;
  color: #96aab4;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-bottom: 15px;
  padding-top: 17px;
  position: relative;
  width: 100%;
}

.botIcon .Input-blank .Input_field {
  max-height: 20px;
}

.botIcon .Input_field {
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 20px;
  padding-right: 45px;
  resize: none;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px !important;
}

.botIcon .Input_button-send {
  right: 15px;
}

.botIcon .Input_button {
  background-color: transparent;
  border: none;
  bottom: 15px;
  cursor: pointer;
  height: 25px;
  outline: none;
  padding: 0;
  position: absolute;
  width: 25px;
}

.Icon svg {
  height: auto;
  width: 100%;
}

.Messages {
  color: #ccc !important;
}

hr {
  border-top: solid 1px rgb(66, 66, 66);
}

.compitorsbox {
  display: flex;
  gap: 20px;
  background: #cccccc5e;
  width: 44%;
  margin: 16px 0 16px 18px;
  padding: 3px 10px;
}

.compitorsbox span {
  display: flex;
}

.productcompit {
  background: transparent;
  margin: 16px 0 16px 10px;
  flex-direction: column;
}

.gray-checkbox {
  color: gray;
}

.customData {
  font-size: 16px;
  font-weight: 600;
}

.data label.form-check-label {
  font-size: 16px;
  font-weight: 600;
}

ul.nav.brand-tabs .active span:after {
  position: absolute;
  background: #fff;
  width: 6px;
  height: 6px;
  content: "";
  top: 4px;
  left: 4px;
  border-radius: 50%;
}

input[type="text"] {
  color: black !important;
  font-weight: 360 !important;
}

.markTable table {
  caption-side: bottom;
  border-collapse: collapse;
  width: fit-content;
  padding: 5px;
}

.markTable tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: rgb(133 111 111) !important;
  border-style: ridge !important;
  border-width: 1px !important;
  padding: 7px;
}

.markTable tr {
  border-width: 1px !important;
}

.markTable th {
  border-width: 1px !important;
}

.big {
  padding: 18px 14px !important;
  border-radius: 2px !important;
  font-size: 20px !important;
  box-shadow: none !important;
  border: 1px solid #9da3a982 !important;
  font-weight: 100 !important;
}

.big1 {
  padding: 6px 5px !important;
  border-radius: 2px !important;
  font-size: 20px !important;
  box-shadow: none !important;
  border: 1px solid #9da3a982 !important;
  font-weight: 100 !important;
}

textarea {
  color: black !important;
  font-weight: 360 !important;
  /* overflow: hidden; */
}

.initialChanges {
  display: flex;
  flex-direction: column;
}

.topData {
  margin-top: -20px !important;
  margin-left: 20px;
}

.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}
