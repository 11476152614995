.header {
  border-bottom: 0.5px solid #cacaca;
  padding: 0.95rem 0.938rem;

  position: sticky;
  background: white;
  top: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 500 !important;
  font-size: 0.895rem !important;
}

.card {
  padding: 0 !important;
  height: 15.5rem;
  overflow-y: auto;
}
.container {
  padding: 0 0.938rem;
  padding-top: 0;
  overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  width: 0; /* For Chrome, Safari, and Opera */
  height: 0; /* Hides the scrollbar on the horizontal axis */
}

.toggleItem {
  font-size: 10px !important;
}
.load_more {
  display: flex;
  justify-content: center;
  margin: 0.7rem 0;
  flex-direction: column;
  align-items: center;
}
.load_btn {
  font-weight: 400 !important;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}
.error_message {
  margin: 0.5rem 0;
}
