.card {
  padding: 0 !important;
}

.attribute_headings {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #333 !important;
  text-align: center !important;
}

.attribute_score {
  font-size: 1rem !important;
  text-align: center !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.table_head {
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid white !important;
}
.table_head_cell {
  padding-top: 0.938rem !important;
  padding-bottom: 0.625rem !important;
  border-bottom: 1px solid white;
}
.table_head_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table_head_title {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.table_head_logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
}
.table_head_text {
  font-size: 0.875rem;
  color: #808080;
  font-weight: 300;
  padding-top: 0.313rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7.5rem;
}

.table_head_text_comp {
  font-size: 1.25rem;
  color: #000;
  font-weight: 500;
}

.error {
  padding: 2rem 0;
}

.core-up-icon {
  margin-top: 5px;
  font-size: 13px;
}

.core-down-icon {
  margin-bottom: 5px;
  font-size: 13px;
}

.coreDelta {
  font-size: 14px;
  line-height: 1.2;
  margin-top: 1px;
  margin-left: -3px;
}
