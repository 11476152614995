.card {
  padding: 0 !important;
}

.title {
  padding: 0.938rem;
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
}

.copy_btn {
  margin-left: 0.313rem;
}