.container {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  grid-template-columns: 1fr auto 1fr auto 1fr;
  gap: 1rem;
  padding: 1.875rem 1.563rem;
}

.title {
  padding: 0.938rem;
  border-bottom: 1px solid #e4e4e4;
}

.card {
  padding: 0 !important;
  position: relative;
}

.error {
  grid-column: span 4;
  padding: 2rem 0;
}

.separator {
  justify-self: center;
  width: 1px;
  height: 100%;
  background: linear-gradient(to bottom, transparent, #e5e7eb 50%, transparent);
  margin: 0 1.875rem;
}

.products_container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 10px;
  margin: 1rem;
}

.chevron_btn {
  position: absolute !important;
  z-index: 1;
  top: 45%;
  background-color: white !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: black !important;
  padding: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 1px solid lightgray !important;
}

.chevron_btn:hover {
  background-color: white;
}

.prev {
  left: 20;
}

.next {
  right: 0;
}
