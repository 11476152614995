.card {
  padding: 0 !important;
}

.table_container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.title {
  padding: 0.938rem;
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 48%;
  max-width: 25rem;
  margin-bottom: 1rem; 
}

.list_btn {
  text-align: center;
  padding: 0.938rem;
}

.list > * {
  width: 100%;
  text-align: center;
}

.list h6 {
  padding-bottom: 0.625rem;
}

.error_view {
  padding: 7rem 0;
}