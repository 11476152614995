.list_item {
  height: 1.875rem;
  padding: 1.563rem 0.875rem !important;
}

.list_item:hover {
  background-color: transparent;
}

.link {
  padding: 0.313rem 0.5rem;
  font-size: 1.125rem;
  color: #000;
}
