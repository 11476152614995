.header {
  padding-top: 1.563rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e4e4e4;
  padding-left: 1.25rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
  margin: 0px 10px
}

.container .MuiCard-root {
  min-width: unset;
}

.card {
  background: #eff4f8;
  padding: 0 !important;
}

.score {
  font-weight: 700;
  font-size: 1.5rem;
}

.subScoreRank,
.subScoreFav {
  font-size: 0.9rem;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
  color: grey;
}

.score:hover,
.subScoreFav:hover,
.subScoreRank:hover {
  color: black;
}

.subContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-top: 1px solid #e4e4e4;
}

.mainHeader {
  padding-top: 1rem;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  min-width: 100%;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0px 5px 10px 5px;
  gap: 10px;
}

.logo {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
}

.mainlogo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 6px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  /* padding: 10px 0 0 0; */
}

.mainTitle {
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10.313rem;
}

.sub_content {
  display: flex;
  flex-direction: column;
}

.divider {
  margin: 0.25rem 0;
}

.add_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.settings {
  position: absolute !important;
  top: 0;
  right: 0;
}

.core-up-icon {
  margin-top: 20px;
  font-size: 20px;
}

.core-down-icon {
  margin-bottom: 0px;
  font-size: 20px;
}

.coreDelta {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.2;
  margin-left: -3px;
  margin-top: -50px;
}

.core-up-icon-2 {
  margin-top: 5.5px;
  font-size: 13px;
}

.core-down-icon-2 {
  margin-bottom: 5px;
  font-size: 13px;
}

.coreDelta2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  margin-left: -5px;
  /* margin-top: -40px; */
}

.subcardsContainer {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 10px;
}

.subcardmain {
  background-color: #f8f9fa !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: transform 0.2s ease-in-out;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  min-height: 100px !important;
  width: 25% !important;
  /* Ensures equal height */
}

.subcardcomp {
  background-color: #f8f9fa !important;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding-top: 5px !important;
  text-align: center !important;
  border-radius: 10px !important;
  transition: transform 0.2s ease-in-out;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center !important;
  min-height: 100px !important;
  width: 12% !important;
  margin-left: 15px !important;
}