.source_list {
  padding: 0;
}

.source_item {
  list-style: none;
}

.source_item a {
  color: #4fd1c5
}
