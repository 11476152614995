.container {
    background-color:#000000cf;
    border-radius: 4px;
    color: white;
    margin-bottom: 40px;
    padding: 20px;
    position: relative;
}

.container table th, td {
   /* border: 1px solid #545454 !important; */
}

.divider {
    border: 1px solid #545454;
}

.badge {
    background-color: #37ad52;
    color: #fff;
    padding: 5px;
    border-radius: 2px;
    position: absolute;
    top: 8px;
    left: -13px;
    z-index: 6;
}

.date {
    display: flex;
    align-self: flex-end;
    font-size: 12px;
    opacity: 0.7;
}