.card {
  padding: 0 !important;
}

.table_head {
  background: #eff4f8;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid white !important;
}

.table_head_cell {
  padding-top: 0.938rem !important;
  padding-bottom: 0.625rem !important;
  border-bottom: 1px solid white;
}

.table_head_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table_head_logo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
}

.table_head_text {
  font-size: 0.875rem;
  color: #808080;
  font-weight: 300;
  padding-top: 0.313rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 7.5rem;
}

.table_head_text_comp {
  font-size: 1.25rem;
  color: #000;
  font-weight: 500;
  white-space: pre-wrap;
  width: 9.375rem;
}

.attribute_headings {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #333 !important;
  text-align: center !important;
}

.attribute_sub_headings {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  color: #333 !important;
  padding-left: 3.125rem !important;
}

.attribute_score {
  font-size: 1rem !important;
  text-align: center !important;
  font-weight: 700 !important;
  cursor: pointer !important;
}

.sub_row {
  background: #f5f8fa !important;
}

.heading_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 12.5rem;
}

.heading_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.625rem;
}
