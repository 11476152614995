.chat-window {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid lightgray;
  padding: 10px 16px;
}

.img {
  width: 17px;
  height: 17px;
  object-fit: contain;
  border-radius: 0.125rem;
}

.name {
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 600;
  font-size: 0.875rem;
}

.chat {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 49%;
  margin-bottom: 18px;
  height: 170px;
}

.chat_full {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 18px;
  min-height: 400px
}

.chat_content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: white;
}

.disabled {
  opacity: 0.5;
  /* Make it look disabled */
  /* pointer-events: none; */
  /* Prevent interaction */
  /* user-select: none; */
  /* Disable text selection */
}

.minMax {
  padding: 2px 5px 2px 2px;
  cursor: pointer;
}