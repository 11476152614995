.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  border: 1px solid #e5e7eb;
  padding: 0.75rem 0.938rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #f9fafb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.container:hover {
  background-color: #f3f4f6;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content_wrapper {
  display: flex;
  align-items: center;
}

.icon {
  color: #626262;
  font-size: 1.7rem;
  align-self: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 0.925rem;
}

.heading {
  font-weight: 500;
  font-size: 0.875rem;
  color: #262626;
}

.description {
  color: #262626;
  font-size: 0.788rem;
}

.date {
  color: #626262;
  font-size: 0.688rem;
}
