.container {
    background-color: #E8EBF1;
    padding: 30px 40px;
    border-radius: 5px;
}
.results {
  background-color: #000000cf;
  color: white;
}
.md {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 12px 25px;
}
.badge_wrapper {
  padding-top: 30px;
  margin-bottom: 15px;
  position: relative;
}
.badge {
  background: #37ad52;
  color: white;
  padding: 5px;
  border-radius: 2px;
  position: relative;
  left: -15px;
}

.actionBtn {
  padding: 7px 12px !important;
  font-size: 18px !important;
  color: rgb(51, 51, 51) !important;
  /* display: flex !important; */
  justify-content: center !important;
  align-items: center !important;
}
