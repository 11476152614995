.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  border: 1px solid #e5e7eb;
  padding: 0.75rem 0.938rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #f9fafb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
