.header {
  padding-top: 1.563rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  min-width: unset;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 10px;
  position: relative;
  min-width: 25%;
  transition: all 0.2s;
  height: 14.3rem;
  overflow: hidden;
}

.container .MuiCard-root {
  min-width: unset;
}

.card {
  background: #eff4f8;
  padding: 0 !important;
}

.container:hover {
  cursor: pointer;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.22);
}

.score {
  font-weight: 700;
  font-size: 3.3rem;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.875rem;
  padding-bottom: 1.875rem;
  padding-top: 1.25rem;
}

.logo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 6px;
}

.title {
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10.313rem;
}

.sub_content {
  display: flex;
  flex-direction: column;
}

.divider {
  margin: 0.313rem 0;
}

.sub_score {
  font-size: 1.225rem;
  font-weight: 600;
}

.add_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.settings {
  position: absolute !important;
  top: 0;
  right: 0;
}

.variant_text {
  color: #007166;
  padding: 0.2rem 0.6rem;
  font-size: 0.72rem;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block !important;
  background-color: #dff9f6;
  width: auto !important;
  flex-grow: 0 !important;
  align-self: flex-start;
}

.title_container {
  display: flex;
  flex-direction: column;
}
