.navList {
  padding: 1rem;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.navItem {
  margin-bottom: 10px;
  border-radius: 4px;
}

.navItem:hover {
  background-color: #d3e8f9;
}

.navItem:last-of-type {
  margin-bottom: 0px;
}

.navLink {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #000;
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.navLinkActive {
  background-color: #fff;
  color: #2d3748;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
}

.iconWrap {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.05);
  width: 30px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapActive {
  background-color: #4fd1c5;
  color: #fff;
}

.feather {
  font-size: 24px;
}

.icon {
 color: #2d3748;
 font-size: 17px !important;
}
.activeIcon {
  color: white;
  font-size: 17px !important;
}

.labelText{
  color: #2d3748;
  font-size: 14px;
  font-weight: 400;
}