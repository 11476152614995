.container {
    position: relative;
    margin: 12px 0px;
}
.loading {
  position: absolute;
  top: 10px;
  right: 5px;
}
.error {
    font-size: 12px;
    color: red;
}
