.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  width: calc(33.333% - 40px); /* Adjust for padding and margin */
}

@media (max-width: 768px) {
  .item {
    width: calc(50% - 40px); /* 2 items per row */
  }
}

@media (max-width: 480px) {
  .item {
    width: calc(100% - 40px); /* 1 item per row */
  }
}
