.message {
  display: flex;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 12px;
  max-width: fit-content;
  position: relative;
}
.assistant {
  background-color: rgb(242 242 242);
}
.user {
  background-color: rgb(73 135 252);
  color: white !important;
}

.messageContainer {
  display: flex;
}
.userContainer {
  flex-direction: row-reverse;
}
.content {
  font-size: 0.775rem;
}

.copy {
  display: inline-block;
  align-self: flex-end;
  position: absolute !important;
  background-color: gray !important;
  bottom: -16px !important;
  right: -0px !important;
}
