.container {
  max-width: 90%;
  margin: auto;
  overflow: auto;
  margin-bottom: 20px;
}
.report_btn {
  width: 15%;
  background-color: #3dc863 !important;
  color: white !important;
  padding: 8px 2px;
}
.report_btn:hover {
  background-color: #3dc863;
}
.table th {
  font-weight: 600;
  font-size: 12px;
  font-family: "Outfit" !important;
  border-color: unset !important;
  text-transform: uppercase;
}
.table td {
  font-size: 13px;
  font-family: "Outfit" !important;
  border-color: unset !important;
}
.chip {
  font-family: "Outfit" !important;
  margin: 2px;
  font-size: 12px;
}
.progress {
  display: "flex";
  flex-direction: "column";
}
.progressText {
  font-size: 12px;
  padding-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.loading {
  display: flex;
  justify-content: center;
}
.no_reports {
  display: flex;
  justify-content: center;
  padding: 15px 0px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding-bottom: 10px;
}

.row {
  display: grid !important;
  grid-template-columns: 1.5fr 2fr 2.5fr 4fr 2fr 1fr 1.5fr;
  border: unset !important;
  padding: 0;
  justify-items: center;
}

.cell {
  min-width: 0;
  width: 100%;
}
.thead {
  border: unset !important;
}
