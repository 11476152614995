.box {
  margin: 0.6rem 20px 0.6rem 0px;
  background-color: #fff;
  border: 1px solid hsl(215, 15%, 92%);
}

.title {
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  background-color: #f7f7f7 !important;
}

.text {
  padding-top: 0.4rem;
  font-size: 0.89rem;
}

.regenerate {
  color: #007aff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.5rem;
}

.details {
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  margin-top: 1rem;
  border-top: 1px solid #ececec;
}

.trash_btn {
  position: absolute !important;
  top: 0.5rem;
  right: 0.5rem;
}

.hint {
  margin-top: 2rem;
  font-size: 1rem;
  color: #767676;
  font-weight: 600;
  text-align: center;
}