.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.logo {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}

.title {
    display: flex;
    align-items: center;
}

.header {
    margin: 0;
}