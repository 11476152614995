.input {
  width: 100%;
  margin-top: 20px;
}

.hint_text {
  font-size: 10px;
  padding-top: 5px;
  display: inline-block;
}

.hint_chip {
  font-size: 10px !important;
  cursor: pointer;
  color: #1976d2;
  padding: 0 4px;
  font-weight: bold;
}