.max-width-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  
}

.image-container {
  width: 100%;
  aspect-ratio: 4 / 3;
  overflow: hidden;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
}

.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container while preserving its aspect ratio */
}
