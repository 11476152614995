.tableContainer {
  max-height: 400px;
  overflow-y: auto;
}

.regenerate {
  color: #007aff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.logo {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  margin-bottom: 5px;
}

.logo4 {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  margin-bottom: 5px;
}

.logo2 {
  width: 19px;
  height: 19px;
  border-radius: 6px;
  margin-bottom: 5px;
  margin-left: 3px;
}

.logo3 {
  width: 21.5px;
  height: 21.5px;
  border-radius: 6px;
  margin-bottom: 5px;
  margin-left: 1.4px;
}
