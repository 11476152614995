.container {
  background-color: #ebf8ff;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.icon {
  background-color: #93d9ff;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.title {
  font-weight: bold;
}
.message {
  margin-top: 0.2rem;
  font-size: 0.813rem;
  font-weight: 400;
  z-index: 1 !important;
  position: relative;
}

.bgIcon {
  font-size: 6rem;
  position: absolute;
  top: -22px;
  right: -15px;
  z-index: 0 !important;
  color: #ebf8ff;
  opacity: 0.2;
}
